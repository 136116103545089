/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import Header from "./header.js"

const Layout = ({ children }) => {

  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <>
      <div className="background-wrapper"
        style={{
          backgroundImage: 'url(images/backgrounds/bg-' + randomNumber(1,1) + '.jpg)'
        }}
      >
        <div className="content-wrapper">
          <Header />
          <main>
            {children}
          </main>
          <footer>
            © {new Date().getFullYear()}{" "}Masked Tube
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
